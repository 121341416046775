$unit: 0.5rem;

//Shortcut for Media Queries
@mixin breakpoint($name) {
    @if map-has-key($breakpoints, $name) {
        @media #{inspect(map-get($breakpoints, $name))} {
            @content;
        }
    }
    @else {
        @warn "Couldn't find a breakpoint named `#{$name}`.";
    }
}

//Generates vendor prefixes for placeholders
@mixin placeholder {
    &::-webkit-input-placeholder {@content};
    &:-moz-placeholder           {@content};
    &::-moz-placeholder          {@content};
    &:-ms-input-placeholder      {@content};
}

@function map-deep-get($map, $keys...) {
    @each $key in $keys {
        $map: map-get($map, $key);
    }
    @return $map;
}

//Math function for indices (ie. multiply a value by itself)
@function pow($number, $exponent) {
    $value: 1;

    @if $exponent > 0 {
        @for $i from 1 through $exponent {
        $value: $value * $number;
        }
    }

    @return $value;
}