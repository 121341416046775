// Fonts

$font-stack-primary: 'Roboto', sans-serif;
$font-stack-headings: 'Libre Bodoni', serif;
$font-stack-coast: 'Outfit', sans-serif;

// General Breakpoints
$breakpoints: (
    'XXS': ( max-width:  30rem ),
    'XS': ( max-width:  40rem ),
    'XS-up': ( min-width:  641px ),
    'S': ( max-width:  48rem ),
    'S-up': ( min-width: 769px ),
    'M': ( max-width:  64rem ),
    'M-up': ( min-width:  64rem ),
    'ML': ( max-width:  75rem ),
    'ML-up': ( min-width:  75rem ),
    'L': ( max-width: 91rem ),
    'XL': ( min-width: 91rem ),
    'XXL': ( min-width: 105rem ),
    'XXXL': ( min-width: 112rem )
);
